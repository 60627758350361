import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class UpdateGroup extends Component {
	constructor(props) {
		super(props);

		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			groupCreated: false,
			dialerId: '',
			dialerName: '',
			groupName: '',
			dialerRows: [],
			dialerList: [],
			dialersList: ''
		}

	}

	componentDidMount() {
		const groupId = this.props.selectedGroupId;
		Fetcher.get(this.state.apiPath + '/api/obd/dialers/name/list')
			.then(res => {
				this.setState({
					dialerList: res
				})
			})
			.catch(err => { console.log('Error in fetching Dialer List', err) });
		Fetcher.get(this.state.apiPath + '/api/obd/group/detail/'+ groupId)
			.then(res => {
				this.setState({
					dialerRows: res.dialers,
					groupName: res.groupName
				})
			})
			.catch(err => { console.log('Error in fetching Dialer Detail', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		const groupCreated = this.state.groupCreated;
		this.setState({
			groupCreated: groupCreated
		});
	}

	handleAddDialerRow = () => {
		if (this.state.dialerId !== '') {
			let dialerName = this.state.dialerId.substring(this.state.dialerId.lastIndexOf(',')+1,this.state.dialerId.length);
			let dialerId = this.state.dialerId.substring(0,this.state.dialerId.lastIndexOf(','));
			this.state.dialerId = dialerId;
			this.state.dialerName =  dialerName;
			const item = {
				dialerId: dialerId,
				dialerName: dialerName
			};
			const checkDialer = obj => obj.dialerName === dialerName;
			console.log("here comes");
			if(this.state.dialerRows.some(checkDialer))
			{
					Notify.notify('This Dialer is already selected');
					return;
			}

            
           this.handleUpdateGroupDialerSubmit();
         
            //     if(this.state.groupCreated){
			//          this.state.dialerRows.push(item);
			//          this.setState({
			// 	         dialerRows: this.state.dialerRows
			//       });
			//       this.state.dialersList = {
			// 	  "dialersList": this.state.dialerRows
			//      }
	     	// }
		}
		else {
			Notify.notify("Please select Dialer");
		}
	};

	handleRemoveDialerRow = (index) => {

		const newDialerRows = [...this.state.dialerRows];
		console.log("*********"+JSON.stringify(newDialerRows));
		const x = newDialerRows.splice(index, 1);
		let obj = JSON.stringify(x);
		let y = JSON.parse(obj);
		console.log("*********"+y[0]['dialerId']);
		console.log("*********"+y[0]['dialerName']);
		let dId = y[0]['dialerId'];
		let dName = y[0]['dialerName'];
		this.state.dialerId = dId;
		this.state.dialerName = dName;

		const formData = cloneDeep(this.state);
		console.log(formData);
		
		const data = {
			"groupId": this.props.selectedGroupId,
			"groupName": formData.groupName,
			"dialerId": formData.dialerId,
			"dialerName": formData.dialerName,
			"operationType":"remove"
		}
		this.updateGroupDialerRemove(data,index);
		
	};

	handleChangeDialer = idx => e => {
		const { name, value } = e.target;
		const dialerRows = [...this.state.dialerRows];
		dialerRows[idx] = {
			[name]: value
		};
		this.setState({
			dialerRows
		});
	};

	//Add Dialer Group Update
    handleUpdateGroupDialerSubmit =() => {
		
		const formData = cloneDeep(this.state);
		console.log(formData);
		
		const data = {
			"groupId": this.props.selectedGroupId,
			"groupName": formData.groupName,
			"dialerId": formData.dialerId,
			"dialerName": formData.dialerName,
			"operationType":"add"
		}
		this.updateGroupDialer(data);
		 
	}

	updateGroupDialer = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/groups/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let groupCreated = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
					console.log("rree");
					
				}
				else if(res.message === 'Either Dialer or Location or Group is missing'){
					Notify.notify(res.message);

				} else {
					Notify.notify(res.message);
					
					const item = {
				        dialerId: this.state.dialerId,
				        dialerName: this.state.dialerName
			        };
			        this.state.dialerRows.push(item);
			        this.setState({
				        dialerRows: this.state.dialerRows
			        });
			        this.state.dialersList = {
				        "dialersList": this.state.dialerRows
			        }
					groupCreated = true;
				}
				this.setState({
					groupCreated: groupCreated
				})

			})
			.catch(err => {
				console.log('Error in Updating Group', err);
				Notify.notify('Error in Updating Group');
				this.setState({
					groupCreated: false
				})
			});
	}


	updateGroupDialerRemove = (data,index) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/groups/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let groupCreated = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				}
				else if(res.message === 'Either Dialer or Location or Group is missing'){
					Notify.notify(res.message);

				} 
				 else {
					Notify.notify(res.message);
					const newDialerRows = [...this.state.dialerRows];
		            newDialerRows.splice(index, 1);
	            	this.setState({
			            dialerRows: newDialerRows,
						groupCreated : true
		            });
		           this.state.dialersList = {
			           "dialersList": newDialerRows
		           }

				   
					
				}

			})
			.catch(err => {
				console.log('Error in Updating Group', err);
				Notify.notify('Error in Updating Group');
				this.setState({
					groupCreated: false
				})
			});
	}






	// Add Group
	handleUpdateGroupSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);
		if(formData.dialerRows.length === 0)
		{
			Notify.notify('Please select atleast one dialer');
			return;
		}
		if(formData.dialersList === '')
		{
			this.state.dialersList = {
				"dialersList": this.state.dialerRows
			}
		}
		const data = {
			"groupId": this.props.selectedGroupId,
			"groupName": formData.groupName,
			"dialerRows": JSON.stringify(this.state.dialersList)
		}
		this.updateGroup(data);
	}

	updateGroup = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/group/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let groupCreated = false;
				if (res.message === 'Parameters Incorrect' || 'Group Name already exists') {
					Notify.notify(res.message);
				} else {
					groupCreated = true;
					Notify.notify(res.message);
				}
				this.setState({
					groupCreated: groupCreated
				})

			})
			.catch(err => {
				console.log('Error in Updating Group', err);
				Notify.notify('Error in Updating Group');
				this.setState({
					groupCreated: false
				})
			});
	}

	render() {

		if (this.state.groupCreated === true) {
			return <Redirect to={'/group-list/'} />
		}

		let submitButtonlabel = 'Update Group';

		const dialerRows = cloneDeep(this.state.dialerRows);
		const allocatedDialerTable = Array.isArray(dialerRows) ? dialerRows.map((item, index) => {
			return <tr key={index}>
				<td>{dialerRows[index].dialerName}</td>
				<td>
					<i onClick={() => this.handleRemoveDialerRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];
		
		const dialerList = cloneDeep(this.state.dialerList);
		let dialerDropdown = '';
		if (!isEmpty(dialerList) && (!('error' in dialerList))) {
			dialerDropdown = Array.isArray(dialerList) ? dialerList.map((obj, index) => {
				return <option value={obj.dialerId+','+obj.dialerName} key={`group${index}`} >{obj.dialerName}</option>
			}) : []

		} else {
			dialerDropdown = <option value="" >No dialer found</option>
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleUpdateGroupSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update Group</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group">
											<Form.Control required name="groupName" type="text" placeholder="Group Name" onChange={e => this.setvalue(e)} value={this.state.groupName} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
								<Col sm={11}>
										<Form.Group className="form-group">
											<Form.Select name="dialerId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Dialer</option>
												{dialerDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<Form.Group className="form-group">
											<i onClick={this.handleAddDialerRow} className="fa fa-plus-circle plus-icon"></i>
										</Form.Group>
									</Col>
								</Row>
								<Row>
								<Col sm={12}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Dialer</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{allocatedDialerTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>
								{/*<Button variant="primary" type="submit">{submitButtonlabel}</Button>*/}
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default UpdateGroup;